// The file contents for the current environment will overwrite these during build.
// The build system defaults to the ${env} environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json` hello.
// LOCAL
// appRootCommon: `https://localhost:44315/api`,
// appRootVehicle: `https://localhost:44311/api`,
// appRootInspectionCentre: `https://localhost:44375/api`,
// appRootAccount: `https://localhost:44346/api`,    vm
// const env = 'https://localhost:44346/api/';
// const configuration = 'https://localhost:44315/api/';
// const env = 'https://algoplusaccountapipt.azurewebsites.net/api';
// const configuration = 'https://algoplusconfigapipt.azurewebsites.net/api';
const env = 'https://accountapi-web-carrefour-poc.azurewebsites.net/api';
const configuration = 'https://configapi-web-carrefour-poc.azurewebsites.net/api';
const notificationHub = `https://bgprocessapi-web-carrefour-poc.azurewebsites.net`;
//const accountplanning = 'https://algoplusjvaccountplanningapidemo.azurewebsites.net/api';
//const cpm = 'https://algopluscpmcpmconfigurationdev.azurewebsites.net/api';
const bot = `https://directline.botframework.com/v3/directline`;
// const notification = `https://algoplusnotificationdev.azurewebsites.net/api`;
// const notificationHub = `https://algoplusnotificationdev.azurewebsites.net`;
// const backgroundTask = `https://algoplusbackgroundtaskdev.azurewebsites.net/api`;
const targetBaseUrl = 'https://localhost:44320/api';
const forecastURL = 'https://localhost:44321/api';

export const environment = {
  showHomeBase:false,
  iscarrefour: true,
  enableSignalR: false,
  isTeamBasedMenus: true,
  isJWTaApiToken: false,
  enableJourney: false,
  showSaveSelections: true,
  notificationAlerts: false,
  NlPAutoComplete: false,
  navContentPaneEnabled: true,
  Notifications: true,
  HomePage_Favorite: false,
  HomePage_UpcomingTask: false,
  HomePage_KPI: false,
  HomePage_Graph: false,
  HomePage_Enable: false,
  Journey_Enable: true,
  Journey_CreateJourney: true,
  Journey_MergeJourney: true,
  Journey_PlannersJourney: true,
  Journey_InlineAnalytics: true,
  Journey_Notifications: true,
  Journey_CreateAlert: false,
  Journey_EnableContentCreation: false,
  Journey_Notes: true,
  KPI_Enable: false,
  KPI_RecommendJourneys: false,
  KPI_HomepageKPIs: false,
  KPI_KpiGraphToggle: false,
  KPI_HideOutcomeColumn: false,
  KPI_Notifications: false,
  msalConfig: {
    clientId: 'f09914eb-4110-4905-928b-5f093d441698',
    authority: 'https://login.microsoftonline.com/54c70f61-fcb4-416a-821d-2c1defe4860f',
    redirectUri: 'https://carrefour.algoplus.com/_admin/Login',
  },
  okta: {
    enableOkta: false,
    addAuthorizationServer: true,
    OKTA_DOMAIN: `dev-30785815.okta.com`,
    CLIENT_ID: `0oa3dvvdjp3CbK5bg5d7`,
    CALLBACK_PATH: '/login/callback',
    AUTHORIZATION_SERVER: `/oauth2/default`,
    PROTOCOL: `https`,
    redirectUri: 'http://carrefour.algoplus.com/login/callback',
    changePassword: 'https://dev-30785815.okta.com/enduser/settings',
    logoutUri: 'http://carrefour.algoplus.com',
    homeUri: 'http://carrefour.algoplus.com',
    showAlgoLogoutPage: false,
    showChangePassword: false,
    isEnable: false,
  },
  agGridLicenseKey: `CompanyName=Algomus Inc,LicensedGroup=Algo,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-037124,SupportServicesEnd=18_March_2024_[v2]_MTcxMDcyMDAwMDAwMA==31baa4eb45af976d60368922cbd068c8`,
  headerLogoURL: '/assets/images/maf_logo.png',
  headerLogoEarth: '',
  headerLogoEarthHeight: 0,
  logoWidthEarth: 0,
  digitaWallet_buttonName: 'open',
  loginLogoURL: 'assets/images/maf_logo.png',
  logoWidth: 150,
  logoWidthLogin: 200,
  logoWidthForget: 200,
  microsoftURL: `https://login.microsoftonline.com/dd70a646-992f-4f0e-a15e-d9c554e1f576/oauth2/v2.0/token`,
  ClientId: 16,
  production: true,
  idleTime: 8,
  fusionChartsKey:
    ' qqA3ydjA5C5B3A5F5G4B3E3A10B5E2B2E2ixrG1C3gcowg1B3I4F-7mC-16B5B2D3D1G1F4H4B1C8A4D2G5G3C1G2B1ippB1A4B4i1A33A18B14utcB4A2H4moA-31A7C8A3D-11geH2C2C5A9B3B5F3G4F1C2F1zllC7E2f1G4i1dB-22C6D6B5jyD3RF1B5wwf1C2A3E2E3G3C9A6E4E4C4G3B2H2H4B-21==',
  AppSubscription: `${configuration}/Subscription`,
  AppUserPreference: `${configuration}/UserPreference`,
  AppTaskRequest: `${configuration}/TaskRequest`,
  AppTask: `${configuration}/Task`,
  AppJourney: `${configuration}/Journey`,
  AppJourneyLookUp: `${configuration}/JounreyLookUp`,
  AppJourneyCollaboration: `${configuration}/JourneyCollaboration`,
  AppJourneyContextLog: `${configuration}/JourneyContextLog`,
  AppJourneyContextLogComment: `${configuration}/JourneyContextLogComment`,
  AppJourneyInsightComments: `${configuration}/JourneyInsightComments`,
  AppJourneyUserActivityLog: `${configuration}/JourneyUserActivityLog`,
  AppPowerBi: `${configuration}/PowerBi`,
  AccessToken: `${configuration}/PowerBI/GetAccessToken`,
  BotSecretKey: `Rran2RlCMGA.j4-nhXh-vpIX15sULpwpGq2FA4A-PIX9q3lgmxomKt0`,
  mapApi: `AIzaSyCH4mv9Pw3dRWz0yUoMyf4IexqPNUBdzvw`,
  utcOffset: 180,
  AppTokenGenerate: `${bot}/tokens/generate`,
  AppTokenRefresh: `${bot}/tokens/refresh`,
  AppConversations: `${bot}/conversations`,
  AppAccount: `${env}/Account`,
  AppClaim: `${env}/Claim`,
  AppClaimGroup: `${env}/ClaimGroup`,
  AppRole: `${env}/Role`,
  AppCalender: `${configuration}/Calender`,
  AppCategory: `${configuration}/Category`,
  AppChannel: `${configuration}/Channel`,
  AppClient: `${configuration}/Client`,
  AppDistributor: `${configuration}/Distributor`,
  AppForms: `${configuration}/Forms`,
  AppSource: `${configuration}/Sourcing`,
  AppGoal: `${configuration}/Goal`,
  AppGoalType: `${configuration}/GoalTypes`,
  AppGoalTenure: `${configuration}/GoalTenureTypes`,
  AppSourceMixForm: `${configuration}/SourceMixForm`,
  AppSourceMixRow: `${configuration}/SourceMixRow`,
  AppSourceMixHeader: `${configuration}/SourceMixHeader`,
  AppCategoryMixRow: `${configuration}/CategoryMixRow`,
  AppCategoryMixHeader: `${configuration}/CategoryMixHeader`,
  AppCategoryMixForm: `${configuration}/CategoryMixForm`,
  AppStoreMixRow: `${configuration}/NewFormRow`,
  AppStoreMixHeader: `${configuration}/NewFormHeader`,
  AppStoreMixForm: `${configuration}/NewForm`,
  AppSameStoreRow: `${configuration}/SameStoreRow`,
  AppSameStoreHeader: `${configuration}/SameStoreHeader`,
  AppSameStoreForm: `${configuration}/SameStoreForm`,
  AppCalenderActivities: `${configuration}/CalendarActivity`,
  AppEventTypes: `${configuration}/EventTypes`,
  AppActionItems: `${configuration}/ActionItems`,
  AppChannelSourceWise: `${configuration}/ChannelSourceWise`,
  AppMenu: `${configuration}/Menu`,
  AppAnnouncement: `${configuration}/Announcement`,
  AppNote: `${configuration}/Note`,
  AppAPBudgetType: ``,
  AppPromotion: ``,
  AppSKU: ``,
  AppSKUPromotion: `Promotion`,
  AppDecayCurve: ``,
  AppItemMaster: ``,
  AppBussinessAttribute: ``,
  AppBussinessInformation: ``,
  AppWorkgroup: ``,
  AppLevel: ``,
  AppUserLevel: ``,
  UploadDoc: ``,
  //UploadDoc: `https://localhost:44399/api/AnomalyDetector/Upload`,
  Anomoly: ``,
  AnomolyFiles: ``,
  SyncPowerBIReports: `${configuration}/PowerBI/GetReportList`,
  NotificationCommon: `${configuration}/notification`,
  NotificationHub: `${notificationHub}/notifications`,
  ApplicationInsights: `${configuration}/ApplicationInsights`,
  AppDocument: ``,
  AppCommonCode: `${configuration}/CommonCode`,
  AppSuggestAdmin: ``,
  AppCurrency: `${configuration}/CurrencySetting`,
  AppTeams: `${env}/Team`,
  AppFBSpreading: `${configuration}/Spreading`,
  AppCurrencyRate: `${configuration}/CurrencyRate`,
  AppBuyerSetting: `${configuration}/BuyerSetting`,
  AppFinanceSetting: `${configuration}/FinancialSetting`,
  AppProductSetting: `${configuration}/ProductSetting`,
  AppFBForcast: `${configuration}/FBForcast`,
  AppItemManager: `${configuration}/ItemManager`,
  AppTeamScreenGroup: `${configuration}/TeamScreenGroup`,
  AppItemForcast: `${configuration}/ItemForecast`,
  AppAccountSettingMapping: `${configuration}/AccountSettingMapping`,
  AppProductSettingMapping: `${configuration}/ProductSettingMapping`,
  FbForecastProfile: `${configuration}/FBMeasureProfile`,
  MsForecastProfile: `${configuration}/MSMeasureProfile`,
  PromotionPlanner: `${configuration}/PromotionPlanner`,
  OrderList: `${configuration}/MSOrderList`,
  AppBulkCommit: `${configuration}/BulkCommitJob`,
  maxPlannerSaveCount: 20,
  Ess: '',
  autoCompleteApi: 'https://autocomplete-api-demo.azurewebsites.net',
  GenAiapi: `https://questionanswer-gen-ai-api-demo.azurewebsites.net`,
  SkylightForecast: `${targetBaseUrl}/Forecast`,
  TargetBaseUrl: `${targetBaseUrl}`,
  outlierManager: `${forecastURL}/OutlierManager`,
  MappingManager: `${forecastURL}/MappingManager`,
  PromoManager: `${forecastURL}/PromoManager`,
  SeasonalityManager: `${forecastURL}/SeasonalityManager`,
  ForecastManager: `${forecastURL}/ForecastManager`,
  ForecastMapping: `${forecastURL}/Lookup`,
  KPIList: `${configuration}/KPI`,
  CoreArea: `${configuration}/CoreArea`,
  KPISubscription: `${configuration}/KPISubscription`,
  KPISlicer: `${configuration}/KPISlicer`,
  GlobalEntity: `${configuration}/Global`,
  Homebase: `${configuration}/HomeBase`,
  POG: `${configuration}/POG`,
  MasterPromo: `${configuration}/MasterPromo`,
  WebPush: `${configuration}/WebPush`,
  VapidPublicKey:"BPfqBgB2oNNQn0Wywm9df6QiLz5fJold15DynhsYPZJIB2LHoPmp7X9G4LQzMUOhLal8z5ZJ1C5rUzwl0_p9Pyo",
  LinkedItemMaster: `${targetBaseUrl}/LinkedItemMaster`,
  WebPushNotification: true,
  KPITile: `${configuration}/KPITile`,
  KPITrack: `${configuration}/KPITrack`,
  KPIError: `${configuration}/KPIErrorInfo`,
  CronSchedule: `${configuration}/CronSchedule`,
  AccountHierarchy: `${configuration}/AccountHierarchy`,
  ItemHierarchy: `${configuration}/ItemHierarchy`,
  CalendarHierarchy: `${configuration}/CalendarHierarchy`,
  ETL: `${configuration}/ETL`
};
