import { USER_ID } from '../keys/keys';

export class OrderList {
  batchId!: number;
  orderLineId!: number;
  orderStatusName!: string;
  orderDate!: Date;
  deliveryDate!: Date;
  orderSource!: string;
  purchaseOrder!: string;
  itemCode!: number;
  retailerSKU!: string;
  ean!: string;
  upc!: string;
  description!: string;
  releaseDate!: Date;
  locationCode!: string;
  shipTO!: string;
  state!: string;
  x_DOC_DC!: string;
  orderQty!: number;
  adjustedOrdQTY!: number;
  dos!: string;
  min!: number;
  max!: number;
  onHand!: string;
  inTransit!: string;
  onOrder!: string;
  effectiveInventory!: string;
  forecastWK1!: number;
  forecastWK2!: number;
  forecastWK3!: number;
  forecastWK4!: number;
  salesCW!: string;
  salesCW_1!: string;
  createdById!: number;
  createdDate!: Date;
  modifiedById!: number;
  modifiedDate!: Date;
  active!: boolean;
  searchText!: string;
  currentUserId!: number;
  accType!: string;
  editPermission!: any;
  upcCode!: string;
  [key: string]: any;
  constructor(orderList?: OrderList) {
    if (orderList) {
      this.batchId = orderList.batchId || 0;
      this.orderLineId = orderList.orderLineId || 0;
      this.orderStatusName = orderList.orderStatusName || '';
      this.orderDate = orderList.orderDate || null;
      this.deliveryDate = orderList.deliveryDate || null;
      this.orderSource = orderList.orderSource || '';
      this.purchaseOrder = orderList.purchaseOrder || '';
      this.itemCode = orderList.itemCode || 0;
      this.retailerSKU = orderList.retailerSKU || '';
      this.ean = orderList.ean || '';
      this.upc = orderList.upc || '';
      this.description = orderList.description || '';
      this.locationCode = orderList.locationCode || '';
      this.shipTO = orderList.shipTO || '';
      this.state = orderList.state || '';
      this.x_DOC_DC = orderList.x_DOC_DC || '';
      this.adjustedOrdQTY = orderList.adjustedOrdQTY || 0;
      this.dos = orderList.dos || '';
      this.min = orderList.min || 0;
      this.max = orderList.max || 0;
      this.onHand = orderList.onHand || '';
      this.inTransit = orderList.inTransit || '';
      this.onOrder = orderList.onOrder || '';
      this.effectiveInventory = orderList.effectiveInventory || '';
      this.forecastWK1 = orderList.forecastWK1 || 0;
      this.forecastWK2 = orderList.forecastWK2 || 0;
      this.forecastWK3 = orderList.forecastWK3 || 0;
      this.forecastWK4 = orderList.forecastWK4 || 0;
      this.salesCW = orderList.salesCW || '';
      this.salesCW_1 = orderList.salesCW_1 || '';
      this.createdById = orderList.createdById || 0;
      this.createdDate = orderList.createdDate || null;
      this.upcCode = orderList.upcCode || '';
      this.modifiedById = orderList.modifiedById || 0;
      this.modifiedDate = orderList.modifiedDate || null;
      this.active = orderList.active || true;
      this.currentUserId = JSON.parse(localStorage.getItem(USER_ID)!);
    }
  }
}
