export const APP = `${window.origin}`;
export const USER_ID = `${APP}:USER:ID`;
export const IS_CUSTOM_LOGIN = `${APP}:CUSTOM:LOGIN`;
export const IS_MSAL_LOGIN = `${APP}:MSAL:LOGIN`;
export const CLIENT_ID = `${APP}:CLIENT:ID`;
export const USER_INFO = `${APP}:USER:INFO`;
export const USER_ROLES = `${APP}:USER:ROLES`;
export const USER_TEAMS = `${APP}:USER:TEAMS`;
export const USER_TOKEN_INFO = `${APP}:USER:TOKEN:INFO`;
export const SELECTED_LANGUAGE = `${APP}:USER:SELECTED::LANGUAGE`;
export const BOT_TOKEN_INFO = `${APP}:BOT:TOKEN:INFO`;
export const MICROSOFT_TOKEN_INFO = `${APP}:MICROSOFT:TOKEN:INFO`;
export const BOT_CONVERSATION_INFO = `${APP}:BOT:CONVERSATION:INFO`;
export const IS_FUNCTIONAL = `${APP}:USER:ROLE:FUNCTIONAL`;
export const IS_PROFILE_EDITOR = `${APP}:USER:ROLE:PROFILE:EDITOR`;
export const IMPORT_ROLE = `${APP}:USER:ROLE:IMPORT`;
export const TRAINING_DOC = `${APP}:USER:TRAINING:DOC:IMPORT`;
export const IS_JOURNEY_ADMIN = `${APP}:USER:ROLE:JOURNEY:ADMIN`;
export const CLIENT_PREFERNCE_KEY = `${APP}:CLIENT_PREFERNCE`;
export const CLIENT_WEEK_START_DAY = `${APP}:CLIENT_WEEK_START_DAY`;
export const USER_PHOTO_URL = `${APP}:CLIENT_WEEK_START_DAY`;
export const IS_WORKER = `${APP}:IS:WORKER`;