import { Component, HostListener, OnInit, TemplateRef, ViewChild, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { interval, Subject } from 'rxjs';
import { environment } from '../environments/environment';
import { SwPush, SwUpdate } from '@angular/service-worker';
import { MatDialog } from '@angular/material/dialog';
import { BotService, LoaderService, LocalstorageService, NotificationService } from '@algomusinc/services';
import { BOT_TOKEN_INFO } from '@algomusinc/domain';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Angular';
  isParentPortal = false;
  userActivity: any;
  userInactive: Subject<any> = new Subject();
  timeOutDuration: any;
  isUpdatedVersion = false;
  version: any = '12345'
  @ViewChild('confirmTemp') confirmTemp!: TemplateRef<any>;
  constructor(
    private swUpdate: SwUpdate,
    private swPush: SwPush,
    public dialog: MatDialog,
    public router: Router,
    public route: ActivatedRoute,
    public storage: LocalstorageService,
    public botService: BotService,
    public loaderService: LoaderService
  ) {}
  ngOnInit(): void {
    this.checkForUpdates();
    if (this.swUpdate.isEnabled) {
      setInterval(() => {
        try {
          this.swUpdate
            .checkForUpdate()
            .then(() => console.log('checking for updates'));
        } catch (err) {
          console.log(err)
        }
      }, 5000)
      // interval(60 * 60).subscribe(() =>
      //   this.swUpdate
      //     .checkForUpdate()
      //     .then(() => console.log('checking for updates'))
      // );
    }
    this.timeOutDuration = environment.idleTime * 60 * 3600;
    this.setTimeout();
  }

  public refreshBotToken() {
    this.botService.RefreshBotToken().subscribe(res => {
      this.storage.set(BOT_TOKEN_INFO, res.token);
    }, error => {
      console.log('Error => ', 'Token not refreshed', error)
    });
  }
  public checkForUpdates(): void {
    this.swUpdate.available.subscribe((event) => {
      if (!this.isUpdatedVersion) {
        this.promptUser();
        this.isUpdatedVersion = true;
      }
    });
  }

  private promptUser(): void {
    console.log('Event Triggered');
    this.dialog.open(this.confirmTemp, {
      hasBackdrop: true,
      disableClose: true,
    });
    // console.log('updating to new version');
    // this.swUpdate.activateUpdate().then(() => document.location.reload());
  }
  checkVersion() {
    navigator.serviceWorker
      .register('/ngsw-worker.js')
      .then((reg: ServiceWorkerRegistration) => {
        // this.dialog.open(this.confirmTemp, {
        //   hasBackdrop: true,
        //   disableClose: true,
        // }).afterClosed().subscribe(res => {
        console.log('ngsw-worker:s', reg);
        reg.update();
        // });
      });
  }


  setTimeout() {
    this.userActivity = setTimeout(
      () => this.userInactive.next(undefined),
      this.timeOutDuration
    );
  }

  @HostListener('window:mousemove') refreshUserStateWhenMouseMoves() {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }
  @HostListener('window:click') refreshStateWhenClicked() {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }
  reload() {
    this.isUpdatedVersion = false;
    localStorage.clear();
    sessionStorage.clear();
    this.deleteAllCookies();
    window.location.reload();
  }
  deleteAllCookies() {
    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  }

}
